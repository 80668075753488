/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { useState, memo } from "react";
import { useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import InklndLogo from "../../../../assets/images/icons/inkind.png";

function LearnMore({ offerData, initialValues, desktop }) {
  const [show, setShow] = useState(true);
  const myRef = useRef(null);
  const executeScroll = () => myRef.current.scrollIntoView();
  return (
    <div className="learn-more-section" ref={myRef}>
      <button
        className={`learn-more ${desktop ? "desktop" : ""}`}
        type="button"
        onClick={executeScroll}
      >
        Learn more
        <FontAwesomeIcon icon={faChevronDown} />
      </button>
      <div
        className={`offer-details show referral-link-details`}
        css={css`
          padding: 5px 20px 15px 20px !important;
        `}
      >
        <h2
          css={css`font-size: 18px!important;font-weight:700!important;margin-top:0;!important`}
        >
          Referral Link Details
        </h2>
        <div className="offer-amount item">
          <h3 className="label">Offer Amount</h3>
          <span className="value">{`$${offerData?.offer?.amount || ""} off $${
            offerData?.offer?.minimum_spend_amount || ""
          }+`}</span>
        </div>
        <div className="referral-bonus item">
          <h3 className="label">Referral Bonus</h3>
          <span className="value">Up to 30% of guest’s bill</span>
        </div>
        <div className="completed-referrals item">
          <h3 className="label">Completed Referrals</h3>
          <span className="value">{offerData?.completed_referrals || 0}</span>
        </div>
        <div className="your-balance item">
          <h3 className="label">Earned with this link</h3>
          <span className="value">{`$${offerData?.earned || 0}`}</span>
        </div>
      </div>
      <div
        className={`offer-details show`}
        css={css`
          padding: 5px 20px 20px 20px !important;
        `}
      >
        <h2
          css={css`font-size: 18px!important;font-weight:700!important;margin-top:0;!important`}
        >
          Ready to spend your referral bonus?
        </h2>
        <div
          className="message item"
          role="button"
          onClick={() => {
            window.open("https://app.inkind.com/", "_blank");
          }}
        >
          <h3
            className="label"
            css={css`
              font-size: 16px;
            `}
          >{`Spend at ${initialValues?.locationsCount}+ restaurants on `}</h3>
          <span className="logo">
            <img src={InklndLogo} className="inkind-logo" alt="InKind Logo" />
          </span>
        </div>
      </div>
    </div>
  );
}

export default memo(LearnMore);
