/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import InklndLogo from "../../../../assets/images/icons/inkind.png";
import Facebook from "../../../../assets/images/ambassador/facebook.png";
import Twitter from "../../../../assets/images/ambassador/link.png";
import Instagram from "../../../../assets/images/ambassador/instagram.png";
import Tiktok from "../../../../assets/images/ambassador/tiktok.png";

export default function Footer() {
  return (
    <footer
      className="footer"
      css={css`
        padding: 30px 20px;
        background: #000;
        text-align: center;
        color: #fff;
      `}
    >
      <div className="inkind-logo">
        <img
          css={css`
            width: 65px;
          `}
          src={InklndLogo}
          className="inkind-logo"
          alt="InKind Logo"
        />
      </div>
      <div
        className="address-and-info"
        css={css`
          margin: 25px 0;
        `}
      >
        <p
          className="info"
          css={css`
            margin: 0;
            font-size: 12px;
            font-weight: 400;
          `}
        >
          &copy; 2024 inKind Cards, Inc.
        </p>
        <p
          className="info"
          css={css`
            margin: 0;
            font-size: 12px;
            font-weight: 400;
          `}
        >
          600 Congress Ave, Suite 1700 · Austin, TX 78701
        </p>
      </div>
      <div
        className="icons"
        css={css`
          display: flex;
          align-items: center;
          justify-content: center;
        `}
      >
        <a
          href="https://www.tiktok.com/@inkindhospitality"
          target="_blank"
          css={css`
            border: none;
            margin-right: 10px;
          `}
        >
          <img
            css={css`
              width: 30px;
            `}
            src={Tiktok}
            alt="Tiktok"
          />
        </a>
        <a
          href="https://www.facebook.com/inkindhospitality/"
          target="_blank"
          css={css`
            margin-right: 10px;
          `}
        >
          <img
            css={css`
              width: 30px;
            `}
            src={Facebook}
            alt="Facebook"
          />
        </a>
        <a
          href="https://www.instagram.com/inkind_hospitality/"
          target="_blank"
          css={css`
            margin-right: 10px;
          `}
        >
          <img
            css={css`
              width: 30px;
            `}
            src={Instagram}
            alt="Instagram"
          />
        </a>
        <a
          href="https://inkind.com/"
          target="_blank"
          css={css`
            margin-right: 10px;
          `}
        >
          <img
            css={css`
              width: 30px;
            `}
            src={Twitter}
            alt="Twitter"
          />
        </a>
      </div>
    </footer>
  );
}
