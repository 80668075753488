/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import TopSection from "./TopSection";
import { calculateFontColorFor } from "./";
import { PhoneIcon, GenerateLinkIcon, ShareNSaveIcon } from "./SVGs";

export default function Signup({ setStep, initialValues }) {
  const signUp = (e) => {
    e.preventDefault();
    setStep(2);
  };

  const textColor = calculateFontColorFor(initialValues?.backgroundColor);

  return (
    <div
      className="signup"
      css={css`
        min-height: 100svh;
        background: ${initialValues?.backgroundColor || "#fff"};
        display: flex;
        flex-direction: column;
      `}
    >
      <TopSection initialValues={initialValues} />
      <div
        className="container"
        style={{
          backgroundColor: initialValues?.backgroundColor || "#fff",
          boxShadow: "none",
          flex: 0,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div
          css={css`
            flex: 1;
            color: ${textColor};
          `}
        >
          <header>
            <h1
              className="main-header-data"
              dangerouslySetInnerHTML={{ __html: initialValues?.header || "" }}
            />
            <p
              dangerouslySetInnerHTML={{ __html: initialValues?.body || "" }}
            />
            <style>{` header::after,.header::after { background-color: ${initialValues?.accentColor}; } `}</style>
          </header>
          <div
            className="icons-and-content"
            css={css`
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              margin-top: 40px;
              padding-left: 20px; /* Adjust this to align the content */
            `}
          >
            <div
              className="content"
              css={css`
                display: flex;
                align-items: center;
              `}
            >
              <div
                className="icon"
                css={css`
                  width: 44px;
                  height: 44px;
                  margin-right: 18px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                `}
              >
                <span>
                  <PhoneIcon color={initialValues?.accentColor} />
                </span>
              </div>
              <div
                className="text-content"
                css={css`
                  text-align: left;
                `}
              >
                <p
                  css={css`
                    font-size: 14px;
                    font-weight: 600;
                    color: ${textColor};
                  `}
                >
                  Sign up
                </p>
                <p
                  css={css`
                    font-size: 14px;
                    font-weight: 400;
                    color: ${textColor};
                    opacity: 50%;
                    padding-bottom: 8px;
                  `}
                >
                  Get your unique ${initialValues.referralAmount} off $
                  {initialValues.minimumSpend}+ link to share
                </p>
              </div>
            </div>
            <div
              className="content"
              css={css`
                display: flex;
                align-items: center;
                margin-bottom: 25px;
              `}
            >
              <div
                className="icon"
                css={css`
                  width: 44px;
                  height: 44px;
                  margin-right: 18px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                `}
              >
                <span>
                  <GenerateLinkIcon color={initialValues?.accentColor} />
                </span>
              </div>
              <div
                className="text-content"
                css={css`
                  text-align: left;
                `}
              >
                <p
                  css={css`
                    font-size: 15px;
                    font-weight: 600;
                    color: ${textColor};
                  `}
                >
                  Share your referral link
                </p>
                <p
                  css={css`
                    font-size: 14px;
                    font-weight: 400;
                    color: ${textColor};
                    opacity: 50%;
                    padding-bottom: 8px;
                  `}
                >
                  Post it in your story, on reviews, anywhere!
                </p>
              </div>
            </div>
            <div
              className="content"
              css={css`
                display: flex;
                align-items: center;
                margin-bottom: 25px;
              `}
            >
              <div
                className="icon"
                css={css`
                  width: 44px;
                  height: 44px;
                  margin-right: 18px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                `}
              >
                <span>
                  <ShareNSaveIcon color={initialValues?.accentColor} />
                </span>
              </div>
              <div
                className="text-content"
                css={css`
                  text-align: left;
                `}
              >
                <p
                  css={css`
                    font-size: 15px;
                    font-weight: 600;
                    color: ${textColor};
                  `}
                >
                  They save, you earn!
                </p>
                <p
                  css={css`
                    font-size: 14px;
                    font-weight: 400;
                    color: ${textColor};
                    opacity: 50%;
                    padding-bottom: 8px;
                  `}
                >
                  Enjoy your earnings at {initialValues.locationsCount}+
                  Restaurants
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          className="button-section"
          css={css`
            text-align: center;
          `}
        >
          <button
            onClick={signUp}
            type="button"
            id="sign-up"
            style={{ backgroundColor: initialValues?.accentColor || "#000" }}
            css={css`
              color: #fff;
              font-size: 15px;
              border-radius: 30px;
              padding: 10px 30px;
              p {
                margin: 0;
              }
            `}
            dangerouslySetInnerHTML={{ __html: initialValues?.button || "" }}
          />
        </div>
      </div>
    </div>
  );
}
